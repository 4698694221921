import { AiFillLinkedin, AiOutlineGithub, AiOutlineMail } from "react-icons/ai";
import "./RightHeroSection.scss";

type RightHeroSectionProps = {};

export const RightHeroSection = (props: RightHeroSectionProps) => {
  return (
    <div className="right-hero-section">
      <h1>Hi;</h1>
      <h2>I am Harsh</h2>
      <h3>Software Engineer by choice.</h3>
      <div className="social-links-row-hero-section">
        <a
          title="LinkedIn (hvg2416)"
          href="https://www.linkedin.com/in/hvg24/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <AiFillLinkedin className="social-link-icon" />
        </a>
        <a
          title="Github (studcroc)"
          href="https://github.com/studcroc/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <AiOutlineGithub className="social-link-icon" />
        </a>
        <a
          title="Gmail"
          href="mailto: hvgautam24.vnit@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <AiOutlineMail className="social-link-icon" />
        </a>
      </div>
    </div>
  );
};
