import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RoutesHandler } from "./RoutesHandler";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ScrollToTop } from "./shared/components/ScrollToTop/ScrollToTop";

const firebaseConfigProd: FirebaseOptions = {
  apiKey: "AIzaSyCu2q1sLnfkLHXV71_NNN4yzto9E2O9YbU",
  authDomain: "portfolio-prod-1d2b1.firebaseapp.com",
  projectId: "portfolio-prod-1d2b1",
  storageBucket: "portfolio-prod-1d2b1.appspot.com",
  messagingSenderId: "266386511301",
  appId: "1:266386511301:web:74d44d2b348b204937a7ac",
};

// const firebaseConfigDev: FirebaseOptions = {
//   apiKey: "AIzaSyD67j4zw_sAemmwZ86_pg4Gb031-KHs950",
//   authDomain: "portfolio-dev-56ae2.firebaseapp.com",
//   projectId: "portfolio-dev-56ae2",
//   storageBucket: "portfolio-dev-56ae2.appspot.com",
//   messagingSenderId: "630356951456",
//   appId: "1:630356951456:web:111cc8f1c878d769e824c5",
//   // measurementId: "${config.measurementId}"
// };

const firebaseConfig: FirebaseOptions = { ...firebaseConfigProd };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = false;

createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <React.StrictMode>
      <ScrollToTop />
      <RoutesHandler />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
